.nav-sections,
.navigation {
  background: none;
}

.nav {
  width: 100%;
}

@include media-breakpoint-down(sm) {

  button.nav-toggle::after {
    background: none !important;
  }
}

.nav-sections {
  position: fixed;
  top: 0;
  margin: 0;
  max-width: 45%;
  height: 100%;
  flex-direction: column;
  overflow: auto;
  transition: right 0.3s;
  left: auto;
  right: -45%;
  z-index: 99;
  width: 100%;
  box-shadow: 0 3px 6px #00000036;

  @include media-breakpoint-down(md) {
    height: calc(100% - 60px);
  }

  &.movetop {
    top: 60px;
    z-index: 20 !important;
  }

  a.nav-link {
    padding: 2.5rem 2.3rem;
    color: $dark;
    font-size: 1.4rem;

    @include media-breakpoint-up(lg) {
      font-size: 1.6rem;
    }

    svg {
      margin-left: 0.95rem;
      margin-bottom: 0.4rem;
    }

    &:hover,
    &:focus {

      @include media-breakpoint-up(lg) {
        box-shadow: 0 3px 6px rgba($black, 0.21);
      }

      span {
        color: $white;
      }

      svg path {
        stroke: $black;
      }
    }

    &:focus-within {
      border-bottom-color: $secondary;
    }

    @include media-breakpoint-down(md) {
      display: flex;
      align-items: center;
      justify-content: space-between;

      svg {

        path {
          stroke: $black;
        }
      }
    }

    @include media-breakpoint-only(lg) {
      padding-left: 0.8rem;
      padding-right: 0.8rem;
    }

    @media (min-width: 1600px) {
      font-size: 2rem;
    }

  }

  @include media-breakpoint-up(lg) {
    position: sticky;
    max-width: 100%;
    width: 100%;
    top: 132px;
    z-index: 9;
    overflow: visible;
  }

  @include media-breakpoint-down(sm) {
    width: 100%;
    max-width: 100% !important;
    top: 135px;
    right: -100%;
  }

  @include media-breakpoint-down(xs) {
    z-index: 9 !important;
  }
}

.page-header.toplevel {
  z-index: 200 !important;
}

.nav-open .page-wrapper {
  left: 0 !important;
}

.nav-open .nav-sections {
  left: auto;
  right: 0;
  box-shadow: none;
}

@include media-breakpoint-down(sm) {

  .nav-open .header-content-container {
    background: $body-bg;

    .block-search .algolia-search-input {
      border: 2px solid $black;
    }
  }
}

.nav-sections-items {
  min-height: 100%;
  background: $white;

  @include media-breakpoint-down(md) {
    display: flex;
    flex-direction: column;
  }
}

.nav-sections-item-title {
  display: none;
}

.nav-sections-item-content {
  margin-top: 0;
  padding: 0;

  @include media-breakpoint-down(md) {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex-grow: 1;
    margin-left: 0;
  }

  @include media-breakpoint-up(lg) {
    position: relative;
  }
}

.category-item.level0 {
  font-family: $headings-font-family;
  font-size: 1.5rem !important;

  &>a {
    text-transform: uppercase;

    @include media-breakpoint-down(lg) {
      font-size: 2rem;
    }
  }

  @media (max-width: 1300px) {
    min-width: 65px;
  }

  @media (min-width: 1501px) and (max-width: 1700px) {
    .category-item.level1 a {
      font-size: 1.4rem !important;
    }
  }

  @include media-breakpoint-only(lg) {

    a {
      font-size: 1.4rem !important;
    }
  }
}

.category-item {
  display: block;
  padding: 0;

  &.level0:not([class*='parent']) {
    box-shadow: none !important;

    &::after,
    >a>svg {
      display: none;
    }
  }

  .level1-listing {
    max-width: max-content !important;

    span {
      margin-right: 7rem;
    }

    &::after {
      right: 0 !important;
    }
  }

  .level1-title, .level1-listing {
    display: none;

    @include media-breakpoint-up(lg) {
      display: block;
    }
  }

  @include media-breakpoint-down(md) {

    &.level0>a>svg {
      display: none;
    }
  }

  @include media-breakpoint-up(lg) {
    position: static !important;

    .submenu-wrapper {
      display: block !important;
      left: -100%;
      transition-property: opacity, left;
      transition-duration: 0.6s, 0s;
      transition-delay: 0s, 0.6s;
    }

    &.level0 {

      &:hover,
      &:focus {
        box-shadow: unset;
      }

      a {
        color: $white;
      }

      .ui-state-selected {
        background: $primary;
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;

        & + .submenu-wrapper {
          max-width: 100%;
          opacity: 1;
          transition-duration: 0s, 0s;
          transition-delay: 0s, 0s;
          z-index: 1;
          left: 0;
        }
      }

      .submenu-items {

        .level0.submenu {
          margin: 4.1rem 0 !important;
          max-width: 333px;
          height: calc(100vh - 250px);
          max-height: 585px;
          overflow-y: auto;
          overflow-x: hidden;
          background: none;
          flex-direction: column;
          display: block !important;

          &::after {
            content: '';
            background: $menu-border-gray;
            width: 1px;
            height: calc(100% - 82px);
            position: absolute;
            top: 41px;
            left: 344px;
          }

          &::-webkit-scrollbar {
            width: 6px;
          }

          &::-webkit-scrollbar-track {
            background: transparent;
          }

          &::-webkit-scrollbar-thumb {
            background: $menu-border-gray;
            border-radius: 20px;
            border: transparent;
          }

          .level1 {
            margin: 0 1.6rem;
            cursor: pointer;

            >a {
              padding-left: 1.6rem;
              padding-right: 6rem;
              border-radius: 5px;

              &.ui-state-selected {
                background-color: $drop-down-gray !important;

                &+.submenu-wrapper {
                  display: block;
                  left: 345px;
                }
              }

            }

            a {
              font-size: 1.8rem;
              color: $black;
              font-family: $font-family-sans-serif;
              min-width: 300px;
            }

            &.single-links {
              display: flex;
              flex-direction: column;
              flex-wrap: wrap;
              max-height: 450px;
              grid-row-start: 1;
              grid-row-end: 4;
              grid-column-start: 3;

              a {
                display: block;
              }

              .level1-title, .submenu {
                display: none !important;
              }
            }

            &-title {
              font-size: 2.4rem;
              line-height: 2.25;
              font-weight: 700;
            }

            &-listing {
              margin-bottom: 5.3rem;
              font-weight: 700;
              text-decoration: underline;
            }

            .submenu-wrapper {
              position: absolute;
              top: 0;
              padding: 0;
              width: calc(100% - 33px);
              height: calc(100% - 82px);
              padding-top: 4.1rem;
              display: none;
              z-index: 11;


              >.submenu-items {
                padding: 0 3.6rem;
                height: 100%;
              }

              .level1.submenu {
                border-top: 1px solid $menu-border-gray;
                padding: 1rem;
                max-height: 358px;
                height: 100%;
                justify-content: start;
                flex-direction: column;
                flex-wrap: wrap;
                max-width: 616px;

                .level2.category-item {
                  max-width: 285px;

                  a {
                    color: $black;
                    margin: 0;
                  }

                  .submenu-wrapper {
                    margin-top: -1rem;

                    .level3.category-item {
                      padding-bottom: 0.5rem;

                      a {
                        margin-bottom: 0;
                        font-size: 1.3rem !important;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        &, .level1 {

          &:not(.single-links)>a,
          .level0-listing > a,
          .level1-listing {
            position: relative;
            &::after {
              content: '';
              position: absolute;
              right: 38px;
              left: unset;
              z-index: 10;
              top: 50%;
              transform: translateY(-50%);
              width: 24px;
              height: 24px;
              background: url(../images/icon-caret-right-menu.svg);
              background-position: center;
              background-repeat: no-repeat;
              background-size: cover;
            }
          }
        }
      }

      &::after {
        content: '';
        display: none;
        background: rgba($black, 0.5);
        height: 100%;
        position: fixed;
        right: 0;
        top: 0;
        width: 100%;
        z-index: -1;
        pointer-events: none;
        left: auto;
      }

      &:focus-within {

        &::after {
          display: block;
        }

        .submenu-wrapper {
          display: flex;
          max-width: 100%;
        }

        >.submenu-wrapper {
          box-shadow: inset 0 3px 6px #00000036;
        }
      }

      >.nav-link {
        position: relative;
        white-space: nowrap;
        line-height: 1;

        &::before {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          left: -1rem;
          right: -1rem;
        }
      }

      @include media-breakpoint-up(xl) {

        >.nav-link::before {
          left: -1.25rem;
          right: -1.25rem;
        }

        &:last-child>.nav-link::before {
          right: 0;
        }
      }
    }
  }
}

.navigation {
  max-width: 100%;
  position: static;
  background: $secondary;

  @include media-breakpoint-down(md) {
    background: $white;
  }

  ul {
    position: static;

    li {

      @include media-breakpoint-down(md) {
        max-width: 440px;
        margin: 0 auto !important;
        width: 100%;
      }

      @include media-breakpoint-down(sm) {
        max-width: 100%;
      }

      @include media-breakpoint-up(lg) {
        position: static;
      }
    }
  }

  .nav-all-link {
    position: absolute;
    right: 7px;
    top: 22px;
    text-decoration: underline;

    @include media-breakpoint-down(sm) {
      top: 0;
    }

    a {
      color: white;
      font-size: 1.4rem;
      height: 50px;
      align-content: center;
      padding: 0 3.2rem;
    }
  }

  .hide-sidebar {
    display: none;
  }

  .nav-footer {

    &,
    li::before {
      display: none;
    }
  }

  @include media-breakpoint-up(lg) {

    ul {
      display: flex;
      padding: 0;
      justify-content: center;
      max-width: 1500px;
      margin: 0 auto;
    }
  }

  @include media-breakpoint-down(md) {
    padding-left: 0;
    padding-right: 0;
    background: $white;

    .nav-footer {
      display: block !important;
      margin-top: 3rem;
      border-top: 2px solid $menu-border-gray;
      padding-bottom: 6rem;

      >div {
        padding: 1.6rem 2.5rem;
        width: 100%;
        font-size: 1.8rem;
        font-weight: bold;

        &::after {
          content: '';
          height: 0.6rem;
          width: 3rem;
          background: #980d19;
          display: block;
        }
      }

      li {
        padding: 0.5rem 1.5rem;

        a {
          width: 100%;
          text-decoration: none;
          padding: 0 !important;
          color: $black;
          font-size: 1.4rem;
          font-weight: normal;
        }
      }

      @include media-breakpoint-down(sm) {

        >div {
          padding: 1.6rem 3.3rem;
          font-size: 2rem;
          text-transform: uppercase;
        }

        ul {
          padding: 0 1.8rem;

          li {
            padding: 0;
            font-size: 1.6rem;
          }
        }
      }
    }

    ul,
    li {
      border: none !important;
    }

    .level0 {
      color: $black;
    }

    li {
      position: relative;
      padding: 0;

      &:not(.single-links)::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 50px;
        height: 100%;
        background-image: url(../images/caret.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: 12px 12px;
        transform: rotate(-90deg);

        @include media-breakpoint-down(sm) {
          right: 17px;
        }
      }

      &.level1 a {
        font-weight: 400 !important;
        padding: 1.2rem 3.4rem !important;
        font-family: $font-family-sans-serif;
      }

      &.hide-before::before {
        display: none;
      }

      a {
        position: relative;
        margin-bottom: 0;
        padding: 1.4rem 1.5rem !important;
        border-bottom: none !important;
        border-left: none !important;
        line-height: 1.75;
        font-weight: bold;
        font-size: 1.6rem !important;

        @include media-breakpoint-down(sm) {
          padding: 1.6rem 3.4rem !important;
        }

        span {
          margin-left: 0 !important;
          color: $black;
        }
      }

      &:first-child a {
        border-top: none;
        margin: 0;
      }
    }

    &.inner {

      .level0>.level0-link {

        &.show {
          /* link a tag */
          display: flex;
          justify-content: space-between;

          &::after {
            content: 'All ';
          }

          >svg {
            display: none;
          }

          +.submenu-wrapper {

            &,
            .submenu {
              display: block !important;
            }
          }
        }
      }

      .submenu-wrapper.show-submenu {

        &,
        .submenu {
          display: block !important;
        }
      }
    }
  }
}

.nav-side-bar.sidebar-heading {
  position: relative;
  height: 50px;
  background: $secondary;
  color: $white;

  @include media-breakpoint-up(lg) {
    height: 45px;
    padding-top: 0.6rem;
  }

  .nav-back {
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    width: 50px;
    height: 100%;
    padding: 0;
    box-shadow: none;
    transform: translateY(-50%);
    margin-right: -1rem;
    border: none;
    border-radius: 0;
    z-index: 1;

    &:hover,
    &:focus,
    &:active {
      background: none;
    }

    svg {
      transform: rotate(90deg);
    }

    @include media-breakpoint-down(xs) {
      margin-right: -5.5rem;
    }
  }

  .nav-title {
    text-align: center;
    font-size: 1.8rem;
    font-family: $font-family-sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 2.7;
    margin: 0;
  }

  .clear {
    background: none !important;
    border-radius: 0;
    color: $white;
    position: absolute;
    top: 50%;
    right: 6rem;
    transform: translateY(-50%);
    font-size: 1.2rem;
  }

  @include media-breakpoint-down(md) {
    padding-left: 0;
    padding-right: 0;
    background: $black;

    ul {
      max-width: 272px;
      margin: 0 auto;

      li {
        border-top-color: $gray-200;
      }
    }

    a.nav-link {
      color: $primary;
      font-size: 1.5rem;
    }
  }
}

.navigation-extra {
  margin-top: auto;

  ul,
  .navigation-extra-after {
    max-width: 395px;
    margin: 0 auto;
    padding: 2.4rem 1.6rem;

    @include media-breakpoint-up(sm) {
      padding-left: 3rem;
      padding-right: 3rem;
    }
  }

  .navigation-extra-after {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 0;

    .nav-contact {
      font-family: $headings-font-family;
      font-size: 3rem;
      text-decoration: none;
      text-transform: uppercase;
      color: inherit;

      @media (max-width: 370px) {
        font-size: 2.8rem;
      }
    }

    .btn {
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
  }

  li {

    button,
    a {
      display: block;
      width: 100%;
      padding: 0.6rem 0;
      color: inherit;
      font-size: 1.3rem;
      text-decoration: none;
      border-radius: 0;

      &:hover,
      &:focus {
        text-decoration: underline;
      }

      &:hover,
      &:focus,
      &:active {
        background: none;
      }

      &:focus,
      &:active {
        box-shadow: 0 0 0 0.2rem rgba($white, 0.25) !important;
      }
    }

    button {
      border: none;
      text-align: left;
    }

    span {
      width: 26px;
      display: inline-block;
      transform: translateY(-3px);
    }
  }
}

.submenu-wrapper {
  display: none;
  background: $white;

  .submenu.submenu {
    position: static;
    box-shadow: none;
    border: none;
    min-width: 0;
    margin-top: 0;
    font-weight: 400;
    font-size: 1.5rem;
    display: flex !important;
    flex-direction: row;

    &::before {
      display: none !important;
    }
  }

  .level0-listing {
    display: none;
  }

  @include media-breakpoint-up(lg) {
    position: absolute;
    z-index: 0;
    top: 100%;
    left: 0;
    width: 100%;
    border-top: 1px solid #fff;

    .submenu-items.submenu-items {

      li {
        margin: 0;
        line-height: 1.5;
        font-size: 1.5rem;
        break-inside: avoid;
        page-break-inside: avoid;
      }
      .level0-listing {
        display: block;
        position: absolute;
        left: 369px;
        top: 44px;
        max-width: 350px;
        width: 100%;

        a {
          display: block;
        }
      }

      a {
        background: none !important;
        border: none !important;
        color: $secondary;
        text-decoration: none;
        margin: 0;
        padding: 1rem 0;
        font-size: 1.8rem;
        max-width: 300px;
      }
    }

    .submenu-items {
      position: relative;
      margin: 0 auto;
      max-width: 1480px;
      width: 100%;

      &::before {
        content: '';
        position: absolute;
        top: 1.5rem;
        right: 0;
        bottom: 3rem;
      }
    }
  }
}

@include media-breakpoint-up(lg) {

  body.menu-active::after {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba($black, 0.5);
    z-index: 2;
  }
}
